import { dataLayerPush } from '@utils/dataLayer'
import { PageDataLayer } from '@utils/dataLayer/types'
import { DependencyList, useEffect } from 'react'

export function useDataLayer(
    dataLayer: PageDataLayer,
    shouldPush = true,
    deps?: DependencyList
) {
    useEffect(() => {
        try {
            if (shouldPush) {
                dataLayerPush(dataLayer)
            }
        } catch (error) {
            console.warn(error)
        }
    }, [deps])
}
