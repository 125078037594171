import { ProductShowcaseProps } from '@components/product/ProductShowcase/view'
import { useDeviceContext } from '@contexts/device'
import { useLoading } from '@contexts/loading'
import { useTrustvoxContext } from '@contexts/trustvox'
import { useLoadedEffect } from '@hooks/useLoadedEffect'
import { useShowCaseImpression } from '@hooks/useShowCaseImpression'
import {
    CampaignData,
    HomeArrangement,
    HomeLayoutData,
    HomeLayoutItem
} from '@store/modules/home/types'
import { productActions } from '@store/modules/product/slice'
import { getRecommendationsThunk } from '@store/modules/recommendations/thunk'
import {
    Recommendation,
    RecommendationsNames,
    ShowcasesData
} from '@store/modules/recommendations/types'
import { useAppDispatch } from '@store/modules/store'
import dynamic from 'next/dynamic'
import { Fragment, useState } from 'react'
import { BannerArea } from './components/BannerArea'
import { Carousel } from './components/Carousel'
import { HomeStaticProps } from './static/types'

import { GooglePublisherTag } from '@components/GooglePublisherTags'
import { useDataLayer } from '@hooks/dataLayer/useDataLayer'
import { formatBannersForHome } from '@utils/dataLayer/format'
import { PageTypes } from '@utils/dataLayer/types'
import { DepartmentSlider } from './components/DepartmentSlider'

const ProductShowcase = dynamic<ProductShowcaseProps>(
    () =>
        import('@components/product/ProductShowcase').then(
            module => module.ProductShowcase
        ),
    { ssr: false }
)

export const homeIO = ({
    homeData,
    staticRecommendations
}: HomeStaticProps) => {
    const { isDesktop } = useDeviceContext()
    const { hideLoading } = useLoading()
    const [recommendations, setRecommendations] =
        useState<ShowcasesData | null>(staticRecommendations)
    const dispatch = useAppDispatch()
    const { updateTrustvoxStars } = useTrustvoxContext()

    useLoadedEffect(() => {
        dispatch(
            getRecommendationsThunk(
                {
                    name: RecommendationsNames.HOME,
                    desktop: isDesktop
                },
                {
                    onSuccess: recommendationsResponse => {
                        setRecommendations(recommendationsResponse)
                        updateTrustvoxStars()
                        dispatch(productActions.finishLoadingProductPrices())
                        useShowCaseImpression()
                    },
                    onError: () => {
                        dispatch(productActions.finishLoadingProductPrices())
                    }
                }
            )
        )
        hideLoading()
    }, [])

    function getRecommendationDataByIndex(layoutItem: HomeLayoutItem) {
        if (
            layoutItem.nome === 'top' ||
            layoutItem.nome === 'middle' ||
            layoutItem.nome === 'bottom'
        ) {
            if (recommendations) {
                return recommendations[layoutItem.nome]
            } else {
                return (
                    staticRecommendations &&
                    staticRecommendations[layoutItem.nome]
                )
            }
        }
    }

    function handleRenderBanners(
        homeSection: HomeArrangement,
        banners: CampaignData[]
    ) {
        if (homeSection === 'lista') {
            return <BannerArea banners={banners} />
        }

        if (homeSection === 'carrossel') {
            return <Carousel banners={banners} />
        }

        return <></>
    }

    function handleRenderProductShowCase(
        homeSection: HomeLayoutItem,
        recommendationSection: Recommendation[]
    ) {
        const orientation = isDesktop ? 'vertical' : 'horizontal'

        if (homeSection.possuiCronometro) {
            return (
                <ProductShowcase
                    recommendations={recommendationSection}
                    chronometer={homeData.cronometro}
                    orientation={orientation}
                />
            )
        }

        if (homeSection.disposicao === '') {
            return (
                <ProductShowcase
                    recommendations={recommendationSection}
                    orientation={orientation}
                />
            )
        }

        return <></>
    }

    function renderHomeItems(homeSection: HomeLayoutItem) {
        const recommendationItem = getRecommendationDataByIndex(homeSection)
        const homeObject = homeData[homeSection.nome]

        if (homeSection.tipo === 'vitrine-recomendacao' && recommendationItem) {
            return handleRenderProductShowCase(homeSection, recommendationItem)
        }

        if (homeSection.tipo === 'banner') {
            return handleRenderBanners(homeSection.disposicao, homeObject)
        }

        if (homeSection.tipo === 'google-publisher-tag') {
            return (
                <div className="mt-14">
                    <GooglePublisherTag
                        adUnit={homeSection.nome}
                        slotId={homeSection.slotId || ''}
                        size={homeSection.disposicao as number[]}
                    />
                </div>
            )
        }

        if (
            homeSection.tipo === 'scroll-horizontal' &&
            homeSection.disposicao === '' &&
            homeObject
        ) {
            return <DepartmentSlider departments={homeObject} />
        }

        return <></>
    }

    const handleRenderHomeLayout = (homeLayout: HomeLayoutData) => {
        const deviceKey = isDesktop ? 'desktop' : 'mobile'
        const layoutBannersHome = homeLayout[deviceKey].filter(
            homeSection => homeSection.tipo === 'banner'
        )

        const bannersHome = layoutBannersHome.map(item => homeData[item.nome])

        useDataLayer({
            pageType: PageTypes.home,
            bannerDetail: formatBannersForHome(bannersHome.flat())
        })

        return homeLayout[deviceKey].map((item, index) => (
            <Fragment key={index}>{renderHomeItems(item)}</Fragment>
        ))
    }

    return {
        handleRenderHomeLayout
    }
}

export type HomeIO = ReturnType<typeof homeIO>
